import { imageConfig, refreshImageConfig, type imageTypes, type refreshImageTypes } from 'config/images';

export const getSizes = (imageType: imageTypes) => {
  const config = imageConfig[imageType];
  return config.breakpoints
    .map((breakpoint, index) => `(min-width: ${breakpoint}px) ${config.sizes[index]}px`)
    .concat(`${config.fallback}px`)
    .join(', ');
};

export const getRefreshSizes = (imageType: refreshImageTypes) => {
  const config = refreshImageConfig[imageType];
  return config.breakpoints
    .map((breakpoint, index) => `(min-width: ${breakpoint}px) ${config.sizes[index]}px`)
    .concat(`${config.fallback}px`)
    .join(', ');
};
