export type imageTypes = keyof typeof imageConfig;

export const imageConfig = {
  articleImage: {
    breakpoints: [768, 480],
    sizes: [1024, 768],
    fallback: 480,
  },
  opener: {
    breakpoints: [1200, 768, 360],
    sizes: [1200, 1024, 768],
    fallback: 360,
  },
  defaultTeaser: {
    breakpoints: [1200, 768],
    sizes: [360, 240],
    fallback: 124,
  },
  doubleTeaser: {
    breakpoints: [480, 360],
    sizes: [768, 480],
    fallback: 360,
  },
  fiveFoldTeaser: {
    breakpoints: [1200, 768],
    sizes: [360, 240],
    fallback: 124,
  },
};

export type refreshImageTypes = keyof typeof refreshImageConfig;

export const refreshImageConfig = {
  articleImage: {
    breakpoints: [768, 480],
    sizes: [1024, 768],
    fallback: 480,
  },
  teaserLarge: {
    breakpoints: [1236, 904],
    sizes: [640, 648],
    fallback: 694,
  },
  teaserMedium: {
    breakpoints: [1236, 904, 744],
    sizes: [300, 308, 648],
    fallback: 695,
  },
  teaserSmall: {
    breakpoints: [1236],
    sizes: [137],
    fallback: 130,
  },
  bannerNavigation: {
    breakpoints: [1280, 768, 375],
    sizes: [1104, 1024, 648],
    fallback: 375,
  },
};
