'use client';
import { useContext, useRef, type PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { useFocusTrap } from '../../../customHooks/useFocusTrap';
import { useKey } from '../../../customHooks/useKey';
import { useScrollLock } from '../../../customHooks/useScrollLock';
import type { PropsWithClassName } from '../../../types/react-props';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

export type ModalProps = PropsWithClassName<
  PropsWithChildren<{
    isOpen?: boolean;
    onClose: () => void;
  }>
>;

const Modal = ({ isOpen = false, onClose, children, className }: ModalProps) => {
  const ref = useRef(null);
  useScrollLock(ref, isOpen);
  useFocusTrap(isOpen, ref);
  useKey(isOpen, 'Escape', onClose);

  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./ModalNew.module.scss') : require('./Modal.module.scss');

  if (!isOpen) {
    return null;
  }

  return (
    <div className={clsx(styles.backdrop, className)} ref={ref}>
      <dialog className={styles.modal} ref={ref}>
        <div className={styles.header}>
          <button className={styles.closeButton} onClick={onClose}>
            <Typography variant="4xs" as="span">
              abbrechen
            </Typography>
            <Icon name="clear" />
          </button>
        </div>
        {children}
      </dialog>
    </div>
  );
};

export default Modal;
