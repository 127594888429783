'use client';
import { useContext } from 'react';
import Image from 'components/UI/Image/Image';
import { Typography } from 'components/UI/Typography';
import { AppContext } from 'context/AppContext';
import type { StoryLinePictureType } from 'types/storylines';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { getSizes } from 'utils/image';

export default function Picture({ model }: { readonly model: StoryLinePictureType }) {
  const { image, title } = model;
  const { caption, copyright, alt } = model.fields;

  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./PictureNew.module.scss') : require('./Picture.module.scss');

  return (
    <figure className={styles.root}>
      <Image className={styles.image} image={image} width={940} alt={alt ?? title} sizes={getSizes('articleImage')} />
      {(!!caption || !!copyright) && (
        <figcaption className={styles.info}>
          {!!caption && (
            <Typography as="div" variant="4xs">
              {caption}
            </Typography>
          )}
          {!!copyright && (
            <Typography className={styles.copyright} as="small" variant="5xs">
              {copyright}
            </Typography>
          )}
        </figcaption>
      )}
    </figure>
  );
}
