'use client';
import { useContext } from 'react';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { Title } from '../../../UI/Title';
import { Typography } from '../../../UI/Typography';
import type { WidgetSeriesProps } from './widget-series-default';
import styles from './widget-series-default.module.scss';

const WidgetSeriesMenu = ({ context }: { context: WidgetSeriesProps }) => {
  const alphabetUpperCase = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

  const { featureFlags } = useContext(AppContext);
  const enabled =
    isFeatureFlagEnabled('ft_new_sendungen_a-z', featureFlags) ||
    isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);

  !enabled && alphabetUpperCase.push('0-9');

  const lettersFromGroups = context.itemGroups?.map((group) => group.model.letter);
  const isLetterDisabled = (char: string) => !lettersFromGroups?.includes(char);

  if (enabled) {
    return (
      <section>
        {!!context.model?.fields?.title && (
          <Title link={context.model?.fields?.link}>{context.model.fields.title}</Title>
        )}

        <div className={`${styles.nav} ${styles.rebrush}`}>
          {alphabetUpperCase.map((char) => {
            const disabled = isLetterDisabled(char);
            return (
              <span key={char} className={disabled ? styles.disabled : ''}>
                {disabled ? char : <a href={`#${char}`}>{char}</a>}
              </span>
            );
          })}
        </div>
      </section>
    );
  }

  return (
    <section>
      {!!context.model?.fields?.title && <Title link={context.model?.fields?.link}>{context.model.fields.title}</Title>}

      <div className={enabled ? `${styles.nav} ${styles.rebrush}` : styles.nav}>
        {alphabetUpperCase.map((char) => (
          <Typography key={char} as="span" variant="m">
            <a href={`#${char}`}>{char}</a>
          </Typography>
        ))}
      </div>
    </section>
  );
};

export default WidgetSeriesMenu;
