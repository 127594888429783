'use client';
import { useContext, useState } from 'react';
import { clsx } from 'clsx';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { AppContext } from '../../../../context/AppContext';
import type { PropsWithClassName } from '../../../../types/react-props';
import { Icon } from '../../../UI/Icon';
import Link from '../../../UI/Link/Link';
import { Title } from '../../../UI/Title';
import type { TitleLink } from '../../../UI/Title/Title';
import { Typography } from '../../../UI/Typography';
import type { MenuItemProps } from './widget-menu-default';

type SeoboxItemProps = {
  children: Array<MenuItemProps>;
  type: string;
  title: string;
  noFollow: boolean;
  childIndex: number;
  valid: boolean;
  active: boolean;
};

export interface WidgetMenuSeoboxProps {
  readonly model: {
    readonly menu: Array<SeoboxItemProps>;
  };
  readonly title?: string;
  readonly link?: TitleLink;
  readonly view?: string;
  readonly id?: string;
  readonly template?: string;
  readonly type?: string;
}

type LinkGroupProps = PropsWithClassName<{
  readonly group: SeoboxItemProps;
}>;

const LinkGroup = ({ group, className }: LinkGroupProps) => {
  const [open, setOpen] = useState(false);

  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh
    ? require('./widget-menu-seobox-new.module.scss')
    : require('./widget-menu-seobox.module.scss');

  return (
    <div className={styles.groupWrapper}>
      <div className={clsx(styles.group, open && styles.open, className)} key={group.title}>
        <Typography as="h3" variant="3xs" className={styles.headline}>
          {group.title}
        </Typography>
        {group.children?.map((link) => (
          <Link
            href={link.url ?? ''}
            target={link.target ?? '_self'}
            key={link.title ?? link.url}
            className={styles.link}
          >
            <Typography variant="4xs">{link.title ?? link.url}</Typography>
          </Link>
        ))}
      </div>
      <button
        className={clsx(
          styles.showMore,
          styles.mobile,
          open && styles.open,
          group.children && group.children?.length <= 5 && styles.hide
        )}
        onClick={() => setOpen(!open)}
      >
        <Typography variant="4xs" as="span">
          {open ? 'weniger' : 'mehr'} anzeigen
        </Typography>
        <Icon name="caretSmallDown"></Icon>
      </button>
    </div>
  );
};

const WidgetMenuSeobox = ({ context }: { context: WidgetMenuSeoboxProps }) => {
  const [open, setOpen] = useState(false);
  const {
    pageMetaData: { noAds },
    featureFlags,
  } = useContext(AppContext);

  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh
    ? require('./widget-menu-seobox-new.module.scss')
    : require('./widget-menu-seobox.module.scss');

  if (noAds) {
    return null;
  }

  return (
    <section>
      {!!context.title && <Title as="h2">{context.title}</Title>}
      <div className={styles.wrapper}>
        {context.model?.menu?.map((group) => (
          <LinkGroup group={group} className={clsx(open && styles.open)} key={group.title} />
        ))}
      </div>
      <button className={clsx(styles.showMore, styles.desktop, open && styles.open)} onClick={() => setOpen(!open)}>
        <Typography variant="4xs" as="span">
          {open ? 'weniger' : 'mehr'} anzeigen
        </Typography>
        <Icon name="caretSmallDown"></Icon>
      </button>
    </section>
  );
};

export default WidgetMenuSeobox;
