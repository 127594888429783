'use client';
import { Fragment, useContext } from 'react';
import { NewsTicker } from 'components/UI/NewsTicker';
import { Title } from 'components/UI/TitleNew';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { linkUrl } from '../../../../../utils/url';
import Link from '../../../../UI/Link/Link';
import { Swiper } from '../../../../UI/Swiper';
import { Typography } from '../../../../UI/Typography';
import styles from './widget-teaser-news-ticker.module.scss';

export type WidgetTeaserNewsTickerProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly anchor?: string;
    };
  };
  readonly items: ReadonlyArray<{
    readonly id: string;
    readonly model: {
      readonly homeSection: string;
      readonly fields: {
        readonly 'seo-title': string;
      };
      readonly lastmodifiedAt: string;
      readonly url: string;
      readonly themenwelt?: {
        readonly section_name?: string;
        readonly logo_colors?: [string, string, string];
      };
    };
  }>;
};

export const getTimestamp = (date: string) => {
  const now = new Date();
  const inputDate = new Date(date);

  const difference = now.getTime() - inputDate.getTime();

  const minutes = Math.round(difference / 60000);

  if (minutes < 60) {
    return `vor ${minutes} Min.`;
  }

  const hours = Math.round(minutes / 60);

  if (hours < 24) {
    return `vor ${hours} Std.`;
  }

  const days = Math.round(hours / 24);

  if (days < 2) {
    return `vor ${days} Tag`;
  }

  return `vor ${days} Tagen`;
};

const WidgetTeaserNewsTicker = ({ context }: { context: WidgetTeaserNewsTickerProps }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const isTeaserRefresh = isFeatureFlagEnabled('ft_temp_enable_refresh_teasers', featureFlags);

  if (isRefresh || isTeaserRefresh) {
    return (
      <section id={context.model?.fields?.anchor} className={styles.section}>
        {!!context.model?.fields?.title && <Title>{context.model.fields.title}</Title>}
        <NewsTicker
          items={context.items.map((item) => ({
            title: item.model.fields['seo-title'],
            category: item.model.themenwelt?.section_name,
            timestamp: getTimestamp(item.model.lastmodifiedAt),
            linkHref: linkUrl(item.model.url),
            logoColors: item.model.themenwelt?.logo_colors,
          }))}
        />
      </section>
    );
  }

  const swiperItems = () =>
    context.items.map((item) => (
      <Fragment key={`news-ticker-item-${item.id}`}>
        <Link href={linkUrl(item.model.url)}>
          <Typography className={styles.headline} as="h3" variant="4xs">
            {item.model.fields['seo-title']}
          </Typography>
        </Link>
        <Typography className={styles.date} variant="5xs">
          {getTimestamp(item.model.lastmodifiedAt)}
        </Typography>
      </Fragment>
    ));

  const identifier = `widget-teaser-news-ticker-${context.model?.fields?.title}`;

  return (
    <Swiper
      title={context.model?.fields?.title}
      items={swiperItems() || []}
      identifier={identifier}
      variant="news"
      anchor={context.model?.fields?.anchor}
    />
  );
};

export default WidgetTeaserNewsTicker;
