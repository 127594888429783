'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { Episode } from '../../../../UI/Episode';
import type { ApiImageProps } from '../../../../UI/Image/Image';
import Image from '../../../../UI/Image/Image';
import { TeaserGridSection } from '../../../../UI/TeaserGridSection';
import type { TitleLink } from '../../../../UI/Title/Title';
import { Typography } from '../../../../UI/Typography';

export type WidgetTeaserRtlPlusFullEpisodesProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<{
    readonly model: {
      readonly id: string;
      readonly fields: {
        readonly title: string;
        readonly 'start-date'?: string;
        readonly url: string;
        readonly 'short-text'?: string;
        readonly 'is-premium'?: boolean | string;
      };
      readonly image?: ApiImageProps;
    };
  }>;
};

const WidgetTeaserRtlPlusFullEpisodes = ({ context }: { context: WidgetTeaserRtlPlusFullEpisodesProps }) => {
  const width = 440;
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh
    ? require('./widget-teaser-rtl-plus-ganze-folgen-new.module.scss')
    : require('./widget-teaser-rtl-plus-ganze-folgen.module.scss');

  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      anchor={context.model?.fields?.anchor}
    >
      {context.items?.map((item, index) => (
        <div key={item.model.id} className={clsx(styles.teaser, { [styles.first]: index === 0 })}>
          <div className={styles.imageWrapper}>
            <Image className={styles.image} image={item.model.image} alt={item.model.fields.title} width={width} />
            <Typography className={styles.badge} variant="4xs" as="div">
              {item.model.fields['is-premium'] === 'true' ? 'Premium' : 'Free'}
            </Typography>
          </div>
          <Episode
            url={item.model.fields.url}
            title={item.model.fields.title}
            index={index}
            startDate={item.model.fields['start-date']}
            shortText={item.model.fields['short-text']}
          />
        </div>
      ))}
    </TeaserGridSection>
  );
};

export default WidgetTeaserRtlPlusFullEpisodes;
