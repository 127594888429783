'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import Link from '../Link/Link';
import { Typography } from '../Typography';

interface EpisodeProps {
  url: string;
  startDate?: string;
  title: string;
  shortText?: string;
  index: number;
}

export const Episode = ({ url, startDate, title, shortText, index }: EpisodeProps) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./EpisodeNew.module.scss') : require('./Episode.module.scss');

  return (
    <Link className={clsx(styles.link, { 'first-teaser': index === 0 })} href={url} target="_blank" noFollow={false}>
      <div className={styles.textWrapper}>
        {startDate && (
          <Typography className={styles.date} variant="4xs" as="div">
            {new Date(startDate).toLocaleDateString('de-DE', {
              weekday: 'short',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </Typography>
        )}
        <Typography className={styles.headline} as="h3" variant={index === 0 ? 's' : '3xs'}>
          {title}
        </Typography>
        <Typography className={styles.description} variant="4xs">
          {shortText}
        </Typography>
      </div>
    </Link>
  );
};
