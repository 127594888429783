'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import Link from 'components/UI/Link/Link';
import { Typography } from 'components/UI/Typography';
import { AppContext } from 'context/AppContext';
import type { PropsWithClassName } from 'types/react-props';
import { isFeatureFlagEnabled } from 'utils/featureFlags';

export type BreadcrumbItem = {
  readonly name: string;
  readonly path: string;
  readonly url: string;
};

export type BreadcrumbProps = PropsWithClassName<{
  readonly items: ReadonlyArray<BreadcrumbItem>;
  readonly title: string;
}>;

export const Breadcrumb = ({ items, className, title }: BreadcrumbProps) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./BreadcrumbNew.module.scss') : require('./Breadcrumb.module.scss');

  if (items.length < 1) return null;
  const cItems = JSON.parse(JSON.stringify(items)) as Array<BreadcrumbItem>;

  cItems[items.length - 1] = {
    name: title,
    path: '',
    url: '',
  };

  return (
    <nav className={clsx(styles.breadcrumb, className ?? '')}>
      {cItems.map((item, index) => {
        if (index + 1 < items.length) {
          return (
            <Typography key={item.path} className={styles.item} variant="5xs" as="span">
              <Link className={styles.link} href={item.path}>
                {item.path === '/' ? 'RTL' : item.name}
              </Link>
            </Typography>
          );
        }

        return (
          <Typography key={item.path} className={styles.item} variant="5xs" as="h1">
            {item.path === '/' ? 'RTL' : item.name}
          </Typography>
        );
      })}
    </nav>
  );
};
