'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import { type ApiImageProps } from 'components/UI/Image/Image';
import { Pills } from 'components/UI/Pills/Pills';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import type { WidgetMenuProps } from '../widget-menu-default';
import styles from './widget-menu-bannerNavigation.module.scss';

export interface WidgetMenuBannerNavigationProps extends WidgetMenuProps {
  readonly items?: ReadonlyArray<{
    readonly type: string;
    readonly model: {
      readonly image: ApiImageProps;
      readonly title: string;
    };
  }>;
}

const WidgetMenuBannerNavigation = ({ context }: { context: WidgetMenuBannerNavigationProps }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);

  let mobileImage, desktopImage;

  if (isRefresh) {
    if (context.items) {
      mobileImage = context?.items?.length >= 1 && context.items[0].type === 'picture' ? context.items[0] : undefined;
      desktopImage = context?.items?.length >= 2 && context.items[1].type === 'picture' ? context.items[1] : undefined;
    }

    return (
      <div className={styles.menu}>
        {(mobileImage || desktopImage) && (
          <div className={styles.imageWrapper}>
            {mobileImage && (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                className={clsx(styles.image, { [styles.mobile]: desktopImage })}
                src={`${mobileImage.model.image.baseUrl}mobileBanner.webp`}
                alt={mobileImage.model.title}
              />
            )}
            {desktopImage && (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                className={clsx(styles.image, styles.desktop)}
                src={`${desktopImage.model.image.baseUrl}mobileBanner.webp`}
                alt={desktopImage.model.title}
              />
            )}
          </div>
        )}
        <div className={styles.navigation}>
          <Pills items={context.model.menu} pillClass="banner-navi-pill" />
        </div>
      </div>
    );
  }

  return null;
};

export default WidgetMenuBannerNavigation;
