'use client';

import { useContext, useRef, useState } from 'react';
import FoundationPlayer from 'components/UI/FoundationPlayer/FoundationPlayer';
import VideoSwiperNew from 'components/UI/VideoSwiper/VideoSwiperNew';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { CreditBox } from '../../../../UI/Creditbox/Creditbox';
import VideoPlayer from '../../../../UI/VideoPlayer/VideoPlayer';
import type { VideoProps } from '../../../../UI/VideoPlayer/VideoPlayerDefault';
import { VideoSwiper } from '../../../../UI/VideoSwiper';
import { videoplayerOnEvents } from './videoEvents';
import styles from './widget-video-with-related.module.scss';

export const WidgetVideoWithRelated = ({ context }: { context: VideoProps }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  const [showRelatedVideos, setShowRelatedVideos] = useState(false);
  const [isAdPlaying, setIsAdPlaying] = useState<boolean>(false);
  const startType = useRef(0);
  const { related = [], ...video } = context;
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [currentAdTime, setCurrentAdTime] = useState<number>(0);
  const [totalClips, setTotalClips] = useState<number>(0);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);

  const currentVideo = currentVideoIndex === -1 ? video : related[currentVideoIndex];

  const { featureFlags } = useContext(AppContext);
  const isFoundationPayer = isFeatureFlagEnabled('ft_temp_enable_foundation-player', featureFlags);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const isNewPlaylist = isFeatureFlagEnabled('ft_temp_enable_refresh_playlist', featureFlags);

  return (
    <div className={styles.main} data-testid="video-player">
      {isFoundationPayer ? (
        <FoundationPlayer
          video={currentVideo}
          postRollDisabled
          showRelatedVideosListener={setShowRelatedVideos}
          isAdPlayingListener={setIsAdPlaying}
          totalClipsListener={setTotalClips}
          totalDurationListener={setTotalDuration}
          currentAdTimeListener={setCurrentAdTime}
          videoPlayingListener={setVideoPlaying}
        />
      ) : (
        <VideoPlayer
          video={currentVideo}
          noPostroll
          onEvents={videoplayerOnEvents({
            setShowRelatedVideos,
            setIsAdPlaying,
            startType,
            setTotalClips,
            setTotalDuration,
            setCurrentAdTime,
          })}
          startType={startType.current}
        />
      )}

      <CreditBox video={currentVideo} />
      {related.length > 0 &&
        (isRefresh || isNewPlaylist ? (
          <VideoSwiperNew
            onClick={(index: number) => {
              startType.current = index;
              setCurrentVideoIndex(index);
            }}
            videoList={related}
            isAdPlaying={isAdPlaying}
            totalAdClips={totalClips}
            totalAdDuration={totalDuration}
            currentAdTime={currentAdTime}
            currentVideoIndex={currentVideoIndex}
            videoPlaying={videoPlaying}
            initHidden={!showRelatedVideos}
            className={styles.swiperNewRoot}
          />
        ) : (
          <VideoSwiper
            videoList={related}
            initHidden={!showRelatedVideos}
            currentVideoIndex={currentVideoIndex}
            title={`${related.length} weitere Videos`}
            onClick={(index: number) => {
              startType.current = 2;
              setCurrentVideoIndex(index);
            }}
            isAdPlaying={isAdPlaying}
            totalAdClips={totalClips}
            totalAdDuration={totalDuration}
            currentAdTime={currentAdTime}
          />
        ))}
    </div>
  );
};
