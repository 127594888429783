'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import type { PartialWidgetContext } from '../../../../../types/cms';
import type { PropsWithClassName } from '../../../../../types/react-props';
import Partials from '../../../Partials';
import type { GroupGridProps } from '../group-grid-default/group-grid-default';

export type GroupGridAutoFillProps = PropsWithClassName<{
  readonly context: GroupGridProps;
}>;

const GroupGridAutoFill = ({ context, className }: GroupGridAutoFillProps) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh
    ? require('./group-grid-auto-fill-new.module.scss')
    : require('./group-grid-auto-fill.module.scss');

  return (
    <div className={clsx(styles.grid, className)}>
      {context.children.map((widget: PartialWidgetContext) => (
        <Partials context={widget} key={widget.id} />
      ))}
    </div>
  );
};

export default GroupGridAutoFill;
