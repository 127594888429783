'use client';
import { Fragment, useContext } from 'react';
import { findPlayableAds, RenderAd } from 'components/UI/AdInsideList/AdInsideList';
import type { ApiImageProps } from 'components/UI/Image/Image';
import Image from 'components/UI/Image/Image';
import Link from 'components/UI/Link/Link';
import { Title } from 'components/UI/Title';
import type { TitleLink } from 'components/UI/Title/Title';
import { Typography } from 'components/UI/Typography';
import { AppContext } from 'context/AppContext';
import type { AdsPropsInList } from 'types/ads.d';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { linkUrl } from 'utils/url';
import styles from './widget-series-default.module.scss';

export type WidgetSeriesProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly adSlots?: Array<AdsPropsInList>;
    };
  };

  readonly itemGroups?: ReadonlyArray<{
    readonly model: {
      readonly letter: string;
    };
    readonly items: ReadonlyArray<{
      readonly id: string;
      readonly model: {
        readonly url: string;
        readonly fields: {
          readonly title: string;
        };
        readonly image?: ApiImageProps;
      };
    }>;
  }>;
};

const WidgetSeriesDefault = ({ context }: { context: WidgetSeriesProps }) => {
  const alphabetUpperCase = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

  const { featureFlags } = useContext(AppContext);
  const enabled =
    isFeatureFlagEnabled('ft_new_sendungen_a-z', featureFlags) ||
    isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);

  !enabled && alphabetUpperCase.push('0-9');

  const newScrollMargin = isFeatureFlagEnabled('ft_temp_new_scroll-margin', featureFlags);

  const lettersFromGroups = context.itemGroups?.map((group) => group.model.letter);

  const width = 940;

  const adsInList = findPlayableAds(context, true);

  if (enabled) {
    return (
      <section className={`${styles.series} ${styles.rebrush}`}>
        {lettersFromGroups?.map((char: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const ad = adsInList[`${char.toLowerCase()}`] as AdItemProp;

          return (
            <Fragment key={`${char}${ad && '-ad'}`}>
              {!!ad && <RenderAd el={ad} />}
              <div className={`${styles.items} ${newScrollMargin && styles.newscroll}`} id={char}>
                <div className={styles.letter}>{char}</div>

                <div className={styles.grid}>
                  {context.itemGroups
                    ?.filter((itemGroup) => itemGroup.model.letter === char)[0]
                    ?.items.map((item) => (
                      <div className={styles.item} key={item.id}>
                        <Link href={linkUrl(item.model.url)}>
                          <Image
                            image={item.model.image}
                            className={styles.image}
                            alt={`Logo von ${item.model.fields.title}`}
                            title={item.model.fields.title}
                            width={160}
                            cropOverride="c1_1"
                          />
                          <div className={styles.kicker}>{item.model.fields.title}</div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </Fragment>
          );
        })}
      </section>
    );
  }

  return (
    <section className={styles.series}>
      {alphabetUpperCase?.map((char: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const ad = adsInList[`${char.toLowerCase()}`] as AdItemProp;

        return (
          <Fragment key={`${char}${ad && '-ad'}`}>
            {!!ad && <RenderAd el={ad} />}
            <div className={styles.items} id={char}>
              <Title>{char}</Title>

              <div className={styles.grid}>
                {context.itemGroups
                  ?.filter((itemGroup) => itemGroup.model.letter === char)[0]
                  ?.items.map((item) => (
                    <div key={item.id}>
                      <Link href={linkUrl(item.model.url)}>
                        <Image
                          image={item.model.image}
                          className={styles.image}
                          alt={item.model.fields.title}
                          title={item.model.fields.title}
                          width={width}
                        />
                        <Typography className={styles.kicker} as="div" variant="s">
                          {item.model.fields.title}
                        </Typography>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </Fragment>
        );
      })}
    </section>
  );
};

export default WidgetSeriesDefault;
