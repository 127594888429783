import type { WidgetTeaserItemProps } from '../../../../../types/cms-teaser';
import { linkUrl } from '../../../../../utils/url';
import { Teaser } from '../../../../UI/Teaser';
import { TeaserGridSection } from '../../../../UI/TeaserGridSection';
import type { TitleLink } from '../../../../UI/Title/Title';

export const isRtlPlusType = (type: string): boolean => type === 'rtl-plus-episodes' || type === 'rtl-plus-series';

export type WidgetTeaserButtonProps = {
  readonly model: {
    readonly fields: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly articleButtonText?: string;
      readonly rtlPlusButtonText?: string;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<WidgetTeaserItemProps>;
};

const WidgetTeaserButton = ({ context }: { context: WidgetTeaserButtonProps }) => {
  if (!context.items || context.items.length <= 0) {
    return null;
  }

  const storylineArticle = !isRtlPlusType(context.items[0].type) ? context.items[0] : null;
  const rtlPlusArticle = context.items[1] && isRtlPlusType(context.items[1].type) ? context.items[1] : null;
  const width = 748;

  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      gridColumns={2}
      anchor={context.model?.fields?.anchor}
    >
      {storylineArticle && (
        <Teaser
          key={storylineArticle.model.id}
          headline={storylineArticle.model.fields.title}
          kicker={storylineArticle.model.fields.subtitle}
          image={{ image: storylineArticle.model.image, width: width, alt: storylineArticle.model.fields.title }}
          buttonText={context.model.fields.articleButtonText ?? 'Artikel lesen auf RTL.de'}
          linkHref={linkUrl(storylineArticle.model.url)}
          isSponsored={storylineArticle.type === 'sponsored-article'}
        />
      )}

      {rtlPlusArticle && (
        <Teaser
          key={rtlPlusArticle.model.id}
          headline={rtlPlusArticle.model.fields.title}
          image={{
            image: rtlPlusArticle.model.image,
            width: width,
            alt: rtlPlusArticle.model.fields.title,
          }}
          linkHref={
            rtlPlusArticle.model.fields.url
              ? linkUrl(rtlPlusArticle.model.fields.url)
              : linkUrl(rtlPlusArticle.model.url)
          }
          buttonText={context.model.fields.rtlPlusButtonText ?? 'Jetzt streamen auf RTL+'}
          buttonVariant="filled"
          openLinkInNewTab
        />
      )}
    </TeaserGridSection>
  );
};

export default WidgetTeaserButton;
