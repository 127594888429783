'use client';
import { useContext, useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { generateWeakKey } from '../../../../../utils/idGenerator';
import { Episode } from '../../../../UI/Episode';
import type { ApiImageProps } from '../../../../UI/Image/Image';
import Image from '../../../../UI/Image/Image';
import { Title } from '../../../../UI/Title';
import type { TitleLink } from '../../../../UI/Title/Title';
import { Typography } from '../../../../UI/Typography';

export type WidgetTeaserRtlPlusLastEpisodesProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<{
    readonly model: {
      readonly id: string;
      readonly fields: {
        readonly title: string;
        readonly 'short-text'?: string;
        readonly 'start-date'?: string;
        readonly url: string;
        readonly 'is-premium'?: boolean | string;
      };
      readonly image?: ApiImageProps;
    };
  }>;
};

const WidgetTeaserRtlPlusLastEpisodes = ({ context }: { context: WidgetTeaserRtlPlusLastEpisodesProps }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh
    ? require('./widget-teaser-rtl-plus-letzte-folgen-new.module.scss')
    : require('./widget-teaser-rtl-plus-letzte-folgen.module.scss');

  const gridRef = useRef<HTMLDivElement>(null);
  const [activeIndicator, setActiveIndicator] = useState(0);

  const width = 440;

  const handleScroll = () => {
    const gridCurrentRef = gridRef.current;

    if (gridCurrentRef !== null) {
      const { scrollLeft, children } = gridCurrentRef;
      const teaserWidth = children[0].clientWidth;
      const activeIndex = Math.round(scrollLeft / teaserWidth);

      setActiveIndicator(activeIndex);
    }
  };

  useEffect(() => {
    const gridCurrentRef = gridRef.current;

    gridCurrentRef?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      gridCurrentRef?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className={styles.section} id={context.model?.fields?.anchor}>
      {!!context.model?.fields?.title && (
        <Title link={context.model?.fields?.link}>{context.model?.fields?.title}</Title>
      )}
      <div className={styles.contentWrapper}>
        <div className={styles.grid} ref={gridRef}>
          {context.items?.map((item, index) => (
            <div key={item.model.id} className={clsx(styles.teaser, { [styles.first]: index === 0 })}>
              <div className={styles.imageWrapper}>
                <Image image={item.model.image} className={styles.image} alt={item.model.fields.title} width={width} />
                <Typography className={styles.badge} variant="4xs" as="div">
                  {item.model.fields['is-premium'] === 'true' ? 'Premium' : 'Free'}
                </Typography>
              </div>
              <Episode
                url={item.model.fields.url}
                title={item.model.fields.title}
                shortText={item.model.fields['short-text']}
                index={index}
                startDate={item.model.fields['start-date']}
              />
            </div>
          ))}
        </div>
        <div className={styles.indicatorWrapper}>
          {context.items?.map((item, index) => (
            <div
              key={`${generateWeakKey(item, 'teaser-rtl-plus-letzte-folgen-indicator')}`}
              className={clsx(styles.indicator, { [styles.active]: activeIndicator === index })}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WidgetTeaserRtlPlusLastEpisodes;
