'use client';
import { useContext, useState } from 'react';
import Script from 'next/script';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { AppContext } from '../../../../../context/AppContext';
import type { StorylineTickarooType } from '../../../../../types/storylines';

const Tickaroo = ({ model }: { model: StorylineTickarooType }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const { prefetchedData } = useContext(AppContext);

  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./TickarooNew.module.scss') : require('./Tickaroo.module.scss');

  return (
    <>
      {
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(prefetchedData?.schema) }}
        />
      }
      <Script
        id={`${model.id}-${model.fields['ticker-id']}`}
        src="https://cdn.tickaroo.com/webng/embedjs/tik4.js"
        onLoad={() => setIsScriptLoaded(true)}
      />
      <div className={styles.root}>
        {isScriptLoaded ? (
          // @ts-expect-error custom html element
          <tickaroo-liveblog
            liveblogId={model.fields['ticker-id']}
            clientId="5c6a79f7baff3e570b518eb5"
            data-testid="tickaroo"
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: prefetchedData ? prefetchedData.html : '' }} />
        )}
      </div>
    </>
  );
};

export default Tickaroo;
