'use client';
import { useContext, type HTMLAttributeAnchorTarget, type ReactNode } from 'react';
import { clsx } from 'clsx';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
import { AppContext } from 'context/AppContext';
import type { PropsWithClassName } from 'types/react-props';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { getReferrer, getRelProperty } from 'utils/getRelProperty';

export type LinkProps = PropsWithClassName<{
  href?: string;
  children: ReactNode;
  target?: HTMLAttributeAnchorTarget;
  plainLink?: boolean;
  noFollow?: boolean;
  title?: string;
  rtlPlus?: boolean;
  onClick?: () => void;
}>;

const Link = ({ children, href = '', target, plainLink = false, noFollow, className, title, onClick }: LinkProps) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./LinkNew.module.scss') : require('./Link.module.scss');

  if (onClick) {
    return (
      <button onClick={onClick} className={styles.button}>
        {children}
      </button>
    );
  }
  const isRTLPlus = getReferrer(href);

  if (plainLink) {
    return (
      <a
        href={href}
        className={clsx(className)}
        rel={getRelProperty(noFollow, target, undefined, isRTLPlus)}
        target={target}
        title={title}
      >
        {children}
      </a>
    );
  }

  return (
    <NextLink
      prefetch={false}
      rel={getRelProperty(noFollow, target, undefined, isRTLPlus)}
      className={clsx(className)}
      href={href ?? ''}
      target={target}
      title={title}
    >
      {children}
    </NextLink>
  );
};

export default Link;
